// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bglmg-page {      
        padding: 0;
}

.bglmg-image{
    background-image: url(https://cdn.wallpapersafari.com/67/54/fMTqrn.jpg);
    background-size: cover;
    background-position: 20px center right;
    height: 300px; 
    margin-top: 2rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/Bglmg.css"],"names":[],"mappings":"AAAA;QACQ,UAAU;AAClB;;AAEA;IACI,uEAAuE;IACvE,sBAAsB;IACtB,sCAAsC;IACtC,aAAa;IACb,gBAAgB;AACpB","sourcesContent":[".bglmg-page {      \n        padding: 0;\n}\n\n.bglmg-image{\n    background-image: url(https://cdn.wallpapersafari.com/67/54/fMTqrn.jpg);\n    background-size: cover;\n    background-position: 20px center right;\n    height: 300px; \n    margin-top: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
